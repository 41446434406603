import React, { createContext, useContext, useEffect, useState } from "react";
import {
  getWebinar,
  getWebinarSurvey,
  getWebinarVideo,
  requestAddMessage,
  requestAddVote,
  SurveyData,
  VideoData,
  WebinarData,
} from "../api";

const webinarContext = createContext<{
  data?: WebinarData;
  video?: VideoData;
  surveys?: SurveyData[];
  fetchWebinar: (
    webinarId: string,
    success_cb: (data: WebinarData) => void,
    error_cb: () => void
  ) => void;
  addVote: (
    videoId: string,
    formId: number,
    questionId: number,
    answerId: number
  ) => void;
  addMessage: (message: string, userName: string) => void;
  isLoading: boolean;
  activeModal: MODAL_TYPE | undefined;
  setActiveModal: (modalType: MODAL_TYPE | undefined) => void;
}>({
  data: undefined,
  fetchWebinar: () => {},
  addVote: () => {},
  addMessage: () => {},
  isLoading: false,
  activeModal: undefined,
  setActiveModal: () => {},
});

export function ProvideWebinar({ children }: { children: JSX.Element }) {
  const webinarProvider = useProvideWebinar();

  return (
    <webinarContext.Provider value={webinarProvider}>
      {children}
    </webinarContext.Provider>
  );
}

export function useWebinar() {
  return useContext(webinarContext);
}

function uniq(array: any[]) {
  return array.sort().filter(function (item: any, pos: any, ary: any) {
    return !pos || item != ary[pos - 1];
  });
}

export enum MODAL_TYPE {
  PRIVACY = "PRIVACY",
}

export function useProvideWebinar() {
  const [data, setData] = useState<WebinarData | undefined>(undefined);
  const [video, setVideo] = useState<VideoData | undefined>(undefined);
  const [surveys, setSurveys] = useState<SurveyData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activeModal, setActiveModal] = useState<MODAL_TYPE | undefined>(
    undefined
  );

  const resetData = () => {
    setSurveys([]);
    setVideo(undefined);
    setData(undefined);
  };

  const fetchWebinar = async (
    webinarId: string,
    success_cb: (data: WebinarData) => void,
    error_cb: () => void
  ) => {
    async function getSurveyRequest() {
      resetData();
      setIsLoading(true);
      const response = await getWebinar(webinarId);
      if (response?.data) {
        const data = { ...response?.data, included: response?.included };
        setData(data);
        return success_cb(data);
      } else {
        setIsLoading(false);
        return error_cb();
      }
    }
    await getSurveyRequest();
  };

  useEffect(() => {
    const video = (data?.included || []).find(
      (_i) => _i.type === "survey_videos_webinar"
    );
    const videoId = video?.attributes?.survey_video_id;
    if (videoId) {
      const getAdditionalData = async () => {
        const webinarVideo = await getWebinarVideo(videoId);
        if (webinarVideo?.data) {
          setVideo(webinarVideo?.data);
          const forms = webinarVideo?.data?.attributes?.survey_forms;
          const surveysToFetch = uniq(
            (forms || []).map(({ survey_form_id }) => survey_form_id)
          );
          const webinarSurveys = await Promise.all(
            (surveysToFetch || []).map((id) => getWebinarSurvey(id))
          );

          if (webinarSurveys) {
            setSurveys(webinarSurveys.map(({ data }) => data));
          }
          setIsLoading(false);
        }
      };
      getAdditionalData();
    }
  }, [data?.id]);

  const addVote = async (
    videoId: string,
    formId: number,
    questionId: number,
    answerId: number
  ) => {
    async function getSurveyRequest() {
      const userUuid = localStorage.getItem(`${data?.id}-auth`);
      const response = await requestAddVote({
        filled_campaign_form: {
          campaign_id: Number(data?.id),
          uuid: userUuid,
          answered_at: new Date(),
          filled_campaign_questions_attributes: [
            {
              scout_question_id: questionId,
              filled_campaign_questions_answers_attributes: [
                {
                  scout_question_answer_id: answerId,
                },
              ],
            },
          ],
        },
      });
    }
    await getSurveyRequest();
  };

  const addMessage = async (message: string, userName: string) => {
    async function addMessageRequest() {
      const userUuid = localStorage.getItem(`${data?.id}-auth`);
      const response = await requestAddMessage({
        message: {
          survey_video_id: Number(video?.id),
          uuid: userUuid,
          webinar_id: data?.id,
          name: userName,
          message,
        },
      });
    }
    await addMessageRequest();
  };

  return {
    data,
    video,
    surveys,
    fetchWebinar,
    addVote,
    addMessage,
    isLoading,
    activeModal,
    setActiveModal,
  };
}
