import React, { useEffect } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";

import { CampaignResources } from "../../../../api";
import useCheckMobileScreen from "../../../../helpers/useCheckMobile";
import TermsModal from "../terms-modal";

interface RouteParams {
  pollingId: string;
}

export interface PollingWelcomeProps {
  campaign: CampaignResources;
}

const PollingPageWelcome = ({
  campaign,
  match,
}: RouteComponentProps<RouteParams> & PollingWelcomeProps) => {
  const pollingId = match.params.pollingId;
  const history = useHistory();
  const isMobile = useCheckMobileScreen();
  const locationQuery = history?.location?.search || '';

  useEffect(() => {
    if (!campaign.attributes.welcoming_screen) {
      history.push(`/polling/${pollingId}/start${locationQuery}`);
    }
  }, []);

  return (
    <section
      className="polling-welcome"
      style={{
        backgroundImage: `url(${
          isMobile
            ? campaign.attributes.welcoming_image_mobile_url
            : campaign.attributes.welcoming_image_desktop_url
        })`,
      }}
    >
      <div className="polling-welcome__content container-fluid">
        {campaign.attributes.welcoming_title && (
          <h1
            className={`${
              campaign.attributes.welcoming_description
                ? ""
                : "polling-welcome__h1--margin"
            }`}
          >
            {campaign.attributes.welcoming_title}
          </h1>
        )}
        {campaign.attributes.welcoming_description && (
          <h3
            dangerouslySetInnerHTML={{
              __html: campaign.attributes.welcoming_description,
            }}
          />
        )}

        <button
          className="button"
          onClick={() => {
            history.push(`/polling/${pollingId}/start${locationQuery}`);
          }}
        >
          {campaign?.attributes?.welcoming_action_text || "BEGIN SURVEY"}
        </button>
      </div>
      {process.env.REACT_APP_LANGUAGE === 'ru' ? <TermsModal /> : null}
    </section>
  );
};

export default PollingPageWelcome;
