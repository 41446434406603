import React from "react";

import "./corner-button.scss";

export const CornerButton = ({
  text,
  onClick,
}: {
  text: string;
  onClick: () => void;
}) => (
  <span className="corner-button" onClick={onClick}>
    {text}
  </span>
);
