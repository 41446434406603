import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation, RouteComponentProps } from "react-router-dom";
import Loader from "react-loader-spinner";
import { differenceInMinutes, differenceInSeconds, format } from "date-fns";

import { useAuth } from "../../helpers/auth";
import useInterval from "../../helpers/useInterval";
import { useWebinar } from "../../helpers/webinarContext";
import { ReactComponent as ArrowIcon } from "../../images/arrow.svg";
import Navbar from "../common/navbar";

import "./home.scss";

const formatTimerDistance = (number: number) => {
  const _number = number % 60;
  if (_number < 1) {
    return "00";
  } else if (_number < 10) {
    return `0${_number}`;
  }

  return `${_number}`;
};

const Timer = ({
  date,
  onFinish,
}: {
  date: Date | undefined;
  onFinish: () => void;
}) => {
  const [timerSeconds, setTimerSeconds] = useState(
    differenceInSeconds(date || new Date(), new Date())
  );
  let minutes = "00";
  let seconds = "00";

  if (date) {
    minutes =
      formatTimerDistance(differenceInMinutes(date, new Date())) || "00";
    seconds = formatTimerDistance(timerSeconds) || "00";
  }

  useInterval(() => {
    if (date) {
      const secondsLeft = differenceInSeconds(date, new Date());

      if (secondsLeft == 0) {
        onFinish();
      }
      setTimerSeconds(secondsLeft);
    }
  }, 1000);

  return (
    <div className="home__timer">
      <div className="home__timer-item">
        <div className="home__timer-number">00</div>
        <span>DAYS</span>
      </div>
      <div className="home__timer-item">
        <div className="home__timer-number">00</div>
        <span>HRS</span>
      </div>
      <div className="home__timer-item home__timer-item--colon">
        <div className="home__timer-number">:</div>
        <span />
      </div>
      <div className="home__timer-item">
        <div className="home__timer-number">{minutes}</div>
        <span>MINS</span>
      </div>
      <div className="home__timer-item home__timer-item--colon">
        <div className="home__timer-number">:</div>
      </div>
      <div className="home__timer-item">
        <div className="home__timer-number">{seconds}</div>
        <span>SECS</span>
      </div>
    </div>
  );
};

interface RouteParams {
  webinarId: string;
}

const HomePage = (props: RouteComponentProps<RouteParams>) => {
  let history = useHistory();
  let auth = useAuth();
  let webinar = useWebinar();
  const webinarId = props.match?.params?.webinarId;
  const alreadySelectedDate = localStorage.getItem(`${webinarId}-date`);
  const sessionDate = alreadySelectedDate
    ? new Date(alreadySelectedDate)
    : undefined;
  const [userName, setUserName] = useState("");
  const [canStartWebinar, setCanStartWebinar] = useState(
    sessionDate ? differenceInSeconds(sessionDate, new Date()) < 0 : false
  );
  const attr = webinar.data?.attributes;

  useEffect(() => {
    async function getSurveyRequest() {
      webinar.fetchWebinar(
        webinarId,
        () => {
          if (!alreadySelectedDate) {
            const coeff = 1000 * 60 * 5;
            const date = new Date();
            const ceilDate = new Date(
              Math.ceil(date.getTime() / coeff) * coeff
            );
            localStorage.setItem(`${webinarId}-date`, ceilDate.toString());
          }
        },
        () => {
          history.push("/");
        }
      );
    }
    getSurveyRequest();
  }, []);

  useEffect(() => {
    if (!webinar.isLoading && attr?.skip_intro) {
      login();
    }
  }, [webinar?.data?.id, attr?.skip_intro, webinar.isLoading]);

  const login = () => {
    if ((canStartWebinar || attr!.skip_intro) && webinar?.data?.id) {
      auth.signin(webinar.data.id, userName, () => {
        const locationQuery = history?.location?.search;
        history.push(`/${webinarId}/webinar${locationQuery ? locationQuery : ''}`);
      });
    }
  };

  if (webinar.isLoading) {
    return (
      <div className="loader-wrapper">
        <Loader type="TailSpin" color="#000" height={50} width={50} />
      </div>
    );
  }

  return (
    <>
      <Navbar hideItems />
      <section className="home">
        <div className="home__header-wrapper container-mobile">
          <header className="home__header">
            <h1>{attr?.title}</h1>
            {attr?.map_url && <img src={attr?.map_url} alt="Map" />}
          </header>
        </div>

        {sessionDate && (
          <div className="home__session-date container-mobile mb2">
            <div>{format(sessionDate, "EEEE, LLLL d, y")}</div>
            <div>{`${format(sessionDate, "h:mm a")} (${
              Intl.DateTimeFormat().resolvedOptions().timeZone
            })`}</div>
            {/*<div className="home__reply-title mb0">*/}
            {/*  <b>Watch yesterday’s replay now</b> <Arrow />*/}
            {/*</div>*/}
          </div>
        )}
        <div className="container-mobile mb">
          {attr?.description && (
            <p dangerouslySetInnerHTML={{ __html: attr.description }} />
          )}
        </div>
        <div className="home__input-wrapper container mb">
          <input
            className="home__input"
            onChange={(e) => setUserName(e.target.value)}
            placeholder="Enter your name (optional)"
            type="text"
            value={userName}
            autoComplete="off"
          />
          <ArrowIcon />
        </div>
        <div className="home__enter mb2">
          <div className="home__enter-container container">
            <img
              className="home__enter-image"
              src={attr?.avatar_url}
              alt="Avatar"
            />
            <div className="home__enter-info">
              <div className="home__enter-info-name">{attr?.host_name}</div>
              <div className="home__enter-info-desc">Webinar Host</div>
            </div>
            <span
              className={`home__enter-button ${
                !canStartWebinar ? "home__enter-button--disabled" : ""
              }`}
              onClick={login}
            >
              Enter Webinar
            </span>
          </div>
        </div>
        <Timer date={sessionDate} onFinish={() => setCanStartWebinar(true)} />
      </section>
    </>
  );
};

export default HomePage;
