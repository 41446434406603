import React from "react";

export const PrivacyPolicy = () => (
  <div className="privacy-policy">
    <p className="MsoNormal">
      <b>
        <span style={{ fontSize: "22.0pt" }}>Privacy Policy</span>
      </b>
    </p>
    <p className="MsoNormal">
      <b>
        <span style={{ fontSize: "18.0pt" }}>&nbsp;</span>
      </b>
    </p>
    <p className="MsoNormal">
      <b>
        <span style={{ fontSize: "12.0pt" }}>
          Effective Date:&nbsp; May 30, 2021
        </span>
      </b>
    </p>
    <p className="MsoNormal">
      <b>
        <span style={{ fontSize: "18.0pt" }}>&nbsp;</span>
      </b>
    </p>
    <p className="MsoNormal">
      <b>
        <span style={{ fontSize: "18.0pt" }}>Table of Contents</span>
      </b>
    </p>
    <p className="MsoNormal">&nbsp;</p>
    <p className="MsoToc1">
      <a href="#_Toc64623429">
        <b>1. Who’s This Policy For And What Does It Cover?</b>
        <b>
          <span
            style={{
              color: "windowtext",
              display: "none",
              textDecoration: "none",
            }}
          >
            .{" "}
          </span>
        </b>
        <b>
          <span
            style={{
              color: "windowtext",
              display: "none",
              textDecoration: "none",
            }}
          >
            1
          </span>
        </b>
      </a>
    </p>
    <p className="MsoToc1">
      <a href="#_Toc64623430">
        <b>2. What is “Personal Information”?</b>
        <b>
          <span
            style={{
              color: "windowtext",
              display: "none",
              textDecoration: "none",
            }}
          >
            .{" "}
          </span>
        </b>
        <b>
          <span
            style={{
              color: "windowtext",
              display: "none",
              textDecoration: "none",
            }}
          >
            2
          </span>
        </b>
      </a>
    </p>
    <p className="MsoToc1">
      <a href="#_Toc64623431">
        <b>3. Personal Information We Collect And How We Collect It</b>
        <b>
          <span
            style={{
              color: "windowtext",
              display: "none",
              textDecoration: "none",
            }}
          >
            {" "}
          </span>
        </b>
        <b>
          <span
            style={{
              color: "windowtext",
              display: "none",
              textDecoration: "none",
            }}
          >
            2
          </span>
        </b>
      </a>
    </p>
    <p className="MsoToc1">
      <a href="#_Toc64623432">
        <b>4. How We Use Personal Information</b>
        <b>
          <span
            style={{
              color: "windowtext",
              display: "none",
              textDecoration: "none",
            }}
          >
            .{" "}
          </span>
        </b>
        <b>
          <span
            style={{
              color: "windowtext",
              display: "none",
              textDecoration: "none",
            }}
          >
            3
          </span>
        </b>
      </a>
    </p>
    <p className="MsoToc1">
      <a href="#_Toc64623433">
        <b>5. How We Share Personal Information</b>
        <b>
          <span
            style={{
              color: "windowtext",
              display: "none",
              textDecoration: "none",
            }}
          >
            .{" "}
          </span>
        </b>
        <b>
          <span
            style={{
              color: "windowtext",
              display: "none",
              textDecoration: "none",
            }}
          >
            3
          </span>
        </b>
      </a>
    </p>
    <p className="MsoToc1">
      <a href="#_Toc64623434">
        <b>6. Children’s Privacy</b>
        <b>
          <span
            style={{
              color: "windowtext",
              display: "none",
              textDecoration: "none",
            }}
          >
            .{" "}
          </span>
        </b>
        <b>
          <span
            style={{
              color: "windowtext",
              display: "none",
              textDecoration: "none",
            }}
          >
            4
          </span>
        </b>
      </a>
    </p>
    <p className="MsoToc1">
      <a href="#_Toc64623435">
        <b>7. Cookies And Online Tracking</b>
        <b>
          <span
            style={{
              color: "windowtext",
              display: "none",
              textDecoration: "none",
            }}
          >
            .{" "}
          </span>
        </b>
        <b>
          <span
            style={{
              color: "windowtext",
              display: "none",
              textDecoration: "none",
            }}
          >
            4
          </span>
        </b>
      </a>
    </p>
    <p className="MsoToc1">
      <a href="#_Toc64623436">
        <b>8. Your Rights And Choices</b>
        <b>
          <span
            style={{
              color: "windowtext",
              display: "none",
              textDecoration: "none",
            }}
          >
            .{" "}
          </span>
        </b>
        <b>
          <span
            style={{
              color: "windowtext",
              display: "none",
              textDecoration: "none",
            }}
          >
            6
          </span>
        </b>
      </a>
    </p>
    <p className="MsoToc1">
      <a href="#_Toc64623437">
        <b>9. Links To Third Party Sites</b>
        <b>
          <span
            style={{
              color: "windowtext",
              display: "none",
              textDecoration: "none",
            }}
          >
            .{" "}
          </span>
        </b>
        <b>
          <span
            style={{
              color: "windowtext",
              display: "none",
              textDecoration: "none",
            }}
          >
            6
          </span>
        </b>
      </a>
    </p>
    <p className="MsoToc1">
      <a href="#_Toc64623438">
        <b>10. Third Party Direct Marketing</b>
        <b>
          <span
            style={{
              color: "windowtext",
              display: "none",
              textDecoration: "none",
            }}
          >
            .{" "}
          </span>
        </b>
        <b>
          <span
            style={{
              color: "windowtext",
              display: "none",
              textDecoration: "none",
            }}
          >
            6
          </span>
        </b>
      </a>
    </p>
    <p className="MsoToc1">
      <a href="#_Toc64623439">
        <b>11. Security</b>
        <b>
          <span
            style={{
              color: "windowtext",
              display: "none",
              textDecoration: "none",
            }}
          >
            .{" "}
          </span>
        </b>
        <b>
          <span
            style={{
              color: "windowtext",
              display: "none",
              textDecoration: "none",
            }}
          >
            6
          </span>
        </b>
      </a>
    </p>
    <p className="MsoToc1">
      <a href="#_Toc64623440">
        <b>12. California Residents</b>
        <b>
          <span
            style={{
              color: "windowtext",
              display: "none",
              textDecoration: "none",
            }}
          >
            .{" "}
          </span>
        </b>
        <b>
          <span
            style={{
              color: "windowtext",
              display: "none",
              textDecoration: "none",
            }}
          >
            6
          </span>
        </b>
      </a>
    </p>
    <p className="MsoToc1">
      <a href="#_Toc64623441">
        <b>13. Data Subjects In The EEA, United Kingdom and Switzerland</b>
        <b>
          <span
            style={{
              color: "windowtext",
              display: "none",
              textDecoration: "none",
            }}
          >
            .{" "}
          </span>
        </b>
        <b>
          <span
            style={{
              color: "windowtext",
              display: "none",
              textDecoration: "none",
            }}
          >
            7
          </span>
        </b>
      </a>
    </p>
    <p className="MsoToc1">
      <a href="#_Toc64623442">
        <b>14. Changes To This Policy</b>
        <b>
          <span
            style={{
              color: "windowtext",
              display: "none",
              textDecoration: "none",
            }}
          >
            .{" "}
          </span>
        </b>
        <b>
          <span
            style={{
              color: "windowtext",
              display: "none",
              textDecoration: "none",
            }}
          >
            7
          </span>
        </b>
      </a>
    </p>
    <p className="MsoToc1">
      <a href="#_Toc64623443">
        <b>15. Contact Us</b>
        <b>
          <span
            style={{
              color: "windowtext",
              display: "none",
              textDecoration: "none",
            }}
          >
            .{" "}
          </span>
        </b>
        <b>
          <span
            style={{
              color: "windowtext",
              display: "none",
              textDecoration: "none",
            }}
          >
            7
          </span>
        </b>
      </a>
    </p>
    <p className="MsoNormal">&nbsp;</p>
    <h1>
      <a id="_Toc64623429">1. Who’s This Policy For And What Does It Cover?</a>
    </h1>
    <p className="MsoNormal">&nbsp;</p>
    <p className="MsoNormal">
      SurveyMiners.com, Inc. (“Survey Miners” or “we”) takes privacy seriously.
      This Privacy Policy (“Policy”) is for visitors to our public website,{" "}
      <a href="http://www.startinfluence.com">www.surveyminers.com</a> (the
      “Site”). It describes how we may collect, use, and share your Personal
      Information in connection with operating the Site. It also explains
      related rights and choices you may have.
    </p>
    <p className="MsoNormal">&nbsp;</p>
    <p className="MsoNormal">
      <span style={{ fontSize: "12.0pt" }}>
        You may see other privacy notices when we collect your Personal
        Information for certain purposes.&nbsp; Those notices supplement this
        Policy.&nbsp;{" "}
      </span>
    </p>
    <p className="MsoNormal">
      <span style={{ fontSize: "12.0pt" }}>&nbsp;</span>
    </p>
    <p className="MsoNormal">
      <span style={{ fontSize: "12.0pt" }}>
        By using the Site, you consent to the practices described in this
        Policy.&nbsp; &nbsp;&nbsp;
      </span>
    </p>
    <p className="MsoNormal">
      <span style={{ fontSize: "12.0pt" }}>&nbsp;</span>
    </p>
    <p className="MsoNormal">
      <span style={{ fontSize: "12.0pt" }}>
        This Policy does not cover our processing of Personal Information on
        behalf of our clients. That processing is governed by the terms of our
        client agreements and laws and regulations applicable to those clients.
      </span>
    </p>
    <p className="MsoNormal">&nbsp;</p>
    <h1>
      <a id="_Toc64623430" />
      <a id="_What_is_“Personal" />
      2. What is “Personal Information”?
    </h1>
    <p className="MsoNormal">&nbsp;</p>
    <p className="MsoNormal">
      When we say “Personal Information,” we mean information that can
      reasonably be linked to a particular individual or their household.
      Generally speaking, your Personal Information includes your unique
      identifiers (full name, phone number, email address, user name, etc.), and
      information about your characteristics, conditions or behavior that is
      associated with one of your identifiers or could otherwise reasonably be
      linked to you.{" "}
    </p>
    <p className="MsoNormal">&nbsp;</p>
    <p className="MsoNormal">
      “Non-Personal Information,” on the other hand, has been anonymized,
      aggregated or de-identified such that it cannot reasonably be linked to a
      particular individual or their household.
    </p>
    <p className="MsoNormal">&nbsp;</p>
    <h1>
      <a id="_Toc64623431">
        3. Personal Information We Collect And How We Collect It
      </a>
    </h1>
    <p className="MsoNormal">&nbsp;</p>
    <h2>Types</h2>
    <p className="MsoNormal">&nbsp;</p>
    <p className="MsoBodyText" style={{ textIndent: "0in" }}>
      The Personal Information that we may collect includes:
    </p>
    <ul style={{ marginTop: "0in" }}>
      <li className="MsoNormal">
        “Identifiers,” such as first and last name, email address, IP address,
        and similar information.
      </li>
      <li className="MsoNormal">
        “Professional or Employment Information,”
        <b>
          <i> </i>
        </b>
        such as company name.
      </li>
      <li className="MsoNormal">
        “Device or Browser Information,” such as the type of device or browser
        you use to access the Site
      </li>
      <li className="MsoNormal">
        “Usage Data,” such as information about how you interact with the Site
        or communications we send
      </li>
      <li className="MsoNormal">
        “User Content,” such as the content of messages you send to us through
        the Site or via email
      </li>
    </ul>
    <br />
    <h2>Collection Sources</h2>
    <p className="MsoNormal">&nbsp;</p>
    <p className="MsoNormal">
      <a id="_Toc31717597">
        <u>From You When You Provide It</u>. We may collect Identifiers,
        Professional or Employment Information and User Content from you
        directly when you fill out a form on the Site, subscribe to receive
        emails or newsletters from us, or otherwise communicate with us.
      </a>
    </p>
    <p className="MsoNormal">&nbsp;</p>
    <p className="MsoNormal">
      <u>From Your Device</u>. Like most online services, our Site may passively
      collect some information from users’ devices in order to secure the Site
      against fraud or misuse, make features function correctly, customize the
      user experience, or understand how the Site is being used. Some of this
      data may be collected through the use of browser cookies or similar
      technologies, which are explained <a href="#_Cookies_And_Online">below</a>
      . In some cases, we limit this type of collection to Non-Personal
      Information. However, this data may include Personal Information such as
      online Identifiers and Usage Data in some circumstances.
    </p>
    <p className="MsoNormal">&nbsp;</p>
    <h1>
      <a id="_Toc64623432" />
      <a id="_How_We_Use" />
      4. How We Use Personal Information
    </h1>
    <p className="MsoNormal">&nbsp;</p>
    <p className="MsoNormal">
      We may use Personal Information for the following purposes:
    </p>
    <ul style={{ marginTop: "0in" }}>
      <li className="MsoNormal">
        To secure our Site against fraud, malicious activity, or conduct that
        may violate our terms of use;
      </li>
      <li className="MsoNormal">
        To personalize and improve your experience on the Site;
      </li>
      <li className="MsoNormal">
        To provide you with information or services that you request from us;
      </li>
      <li className="MsoNormal">
        To notify you about changes to our Site and any products or services we
        offer or provide through them;
      </li>
      <li className="MsoNormal">
        To allow you to participate in interactive features on the Site;
      </li>
      <li className="MsoNormal">
        To provide you with periodic product newsletters and offers;
      </li>
      <li className="MsoNormal">
        To contact you and provide you with help if you have a customer service
        issues;
      </li>
      <li className="MsoNormal">
        To contact you to fill out surveys and participate in other types of
        market research;
      </li>
      <li className="MsoNormal">
        To measure performance of our marketing efforts;
      </li>
      <li className="MsoNormal">
        To notify you of new products or services we think you might be
        interested in;
      </li>
      <li className="MsoNormal">To comply with our legal obligations</li>
      <li className="MsoNormal">
        In any other way we may describe when we collect the information; and
      </li>
      <li className="MsoNormal">For any other purpose with your consent.</li>
    </ul>
    <p className="MsoNormal" style={{ marginLeft: ".5in" }}>
      &nbsp;
    </p>
    <p className="MsoNormal">
      We may use Non-Personal Information for any purpose.
    </p>
    <p className="MsoNormal">&nbsp;</p>
    <h1>
      <a id="_Toc64623433">5. How We Share Personal Information</a>
    </h1>
    <p className="MsoNormal">&nbsp;</p>
    <h2>Affiliates</h2>
    <p className="MsoBodyText" style={{ textIndent: "0in" }}>
      &nbsp;
    </p>
    <p className="MsoBodyText" style={{ textIndent: "0in" }}>
      We may share Personal Information with our corporate affiliates (parent
      companies or subsidiaries) for business purposes.
    </p>
    <h2>&nbsp;</h2>
    <h2>Vendors</h2>
    <p className="MsoNormal">&nbsp;</p>
    <p className="MsoNormal">
      We may share Personal Information with contractors, service providers, and
      other vendors who assist or support us in providing the Site. Examples of
      these third party services may include hosting our Site, storing data, or
      performing analytics.{" "}
      <span style={{ fontSize: "12.0pt" }}>
        Where necessary and appropriate, our agreements with vendors prohibit
        them from retaining, using or disclosing the Personal Information we
        share with them for any purpose other than providing services to us.
      </span>
    </p>
    <p className="MsoNormal">&nbsp;</p>
    <h2>Mandatory Disclosures And Legal Proceedings</h2>
    <p className="MsoNormal" style={{ pageBreakAfter: "avoid" }}>
      <b>
        <span style={{ fontSize: "14.0pt" }}>&nbsp;</span>
      </b>
    </p>
    <p className="MsoNormal" style={{ marginBottom: "12.0pt" }}>
      <span style={{ fontSize: "12.0pt" }}>
        We may have a legal obligation to disclose Personal Information to
        government authorities or other third parties pursuant to a valid
        regulatory request, subpoena or court order.&nbsp; We may also need to
        disclose and otherwise process your Personal Information in accordance
        with applicable law to prevent physical harm or financial loss, protect
        the vital interests of a person, enforce our various policies or terms
        of use, protect our property, services and legal rights, prevent fraud,
        support auditing, compliance and corporate governance functions, or
        comply with applicable law.{" "}
      </span>
    </p>
    <h2>Change In Control Or Merger</h2>
    <p className="MsoNormal">
      <span style={{ fontSize: "12.0pt" }}>&nbsp;</span>
    </p>
    <p className="MsoNormal">
      <span style={{ fontSize: "12.0pt" }}>
        We may transfer your information in the event of the sale of
        substantially all of the assets of our business to a third-party or in
        the event of a corporate merger, consolidation, acquisition or
        reorganization. However, in such event, any acquirer will be subject to
        the provisions of our commitments to you or we will not disclose your
        information.
      </span>
    </p>
    <p className="MsoNormal">
      <span style={{ fontSize: "14.0pt" }}>&nbsp;</span>
    </p>
    <h2>With Your Direction Or Consent</h2>
    <p className="MsoNormal">
      <span style={{ fontSize: "12.0pt" }}>&nbsp;</span>
    </p>
    <p className="MsoNormal" style={{ marginBottom: "12.0pt" }}>
      <span style={{ fontSize: "12.0pt" }}>
        We will share your Personal Information with other third parties as you
        may direct or otherwise consent.
      </span>
    </p>
    <h1>
      <a id="_Toc64623434">6. Children’s Privacy</a>
    </h1>
    <p className="MsoNormal">
      <span style={{ fontSize: "12.0pt" }}>&nbsp;</span>
    </p>
    <p className="MsoNormal">
      Our Site is not directed to children (individuals under the age of 18).
      &nbsp;We do not knowingly collect, maintain, or process children’s
      Personal Information unless the child’s parent or guardian consents and
      provides the information. If we determine that we have received a child’s
      Personal Information from a source other than the child’s consenting
      parent/guardian, we will immediately delete the information.
    </p>
    <p className="MsoNormal" style={{ marginBottom: "12.0pt" }}>
      <span style={{ fontSize: "12.0pt" }}>&nbsp;</span>
    </p>
    <h1>
      <a id="_Toc43116426" />
      <a id="_Toc64623435" />
      <a id="_Cookies_And_Online" />
      7. Cookies And Online Tracking
    </h1>
    <p className="MsoNormal">
      <span style={{ fontSize: "12.0pt" }}>&nbsp;</span>
    </p>
    <h2>What Are Cookies?</h2>
    <p className="MsoNormal">
      <span style={{ fontSize: "12.0pt" }}>&nbsp;</span>
    </p>
    <p className="MsoNormal">
      <span style={{ fontSize: "12.0pt" }}>
        A browser cookie is a small piece of data that a website stores in your
        device’s browser.&nbsp; It holds information that the site uses to
        function properly, remember your preferences, or personalize your
        experience.&nbsp; A typical cookie contains a cookie ID, which is a
        unique string of characters used to identify your browser during the
        browsing session, or in some cases, during subsequent browsing
        sessions.&nbsp; Cookies, cookie ID’s and similar pieces of data may be
        considered Personal Information (Identifiers or Internet Activity) under
        applicable law.
      </span>
    </p>
    <p className="MsoNormal">
      <span style={{ fontSize: "12.0pt" }}>&nbsp;</span>
    </p>
    <p className="MsoNormal">
      <span style={{ fontSize: "12.0pt" }}>
        Other online technologies, similar to cookies, may allow users to be
        identified and tracked across multiple browsing sessions, and in some
        cases tracked across different websites and online services for
        marketing or advertising purposes.&nbsp; These technologies include, but
        are not necessarily limited to, pixels, web beacons and scripts
        (collectively with browser cookies, “Cookies”).
      </span>
    </p>
    <p className="MsoNormal">
      <span style={{ fontSize: "12.0pt" }}>&nbsp;</span>
    </p>
    <h2>
      <a id="_How_Cookies_May" />
      How Cookies May Be Used On Our Site
    </h2>
    <p className="MsoNormal">
      <span style={{ fontSize: "12.0pt" }}>&nbsp;</span>
    </p>
    <p className="MsoNormal">
      <span style={{ fontSize: "12.0pt" }}>
        Our Site may use “first party” Cookies, which are set by our web domain
        (www.surveyminers.com).&nbsp; We don’t allow any third parties to place
        Cookies through our Site.&nbsp;{" "}
      </span>
    </p>
    <p className="MsoNormal">
      <span style={{ fontSize: "12.0pt" }}>&nbsp;</span>
    </p>
    <p className="MsoNormal">
      <span style={{ fontSize: "12.0pt" }}>
        Cookies may be used on our Site for the following purposes:
      </span>
    </p>
    <p className="MsoNormal">
      <span style={{ fontSize: "12.0pt" }}>&nbsp;</span>
    </p>
    <p className="MsoListParagraphCxSpFirst" style={{ textIndent: "-.25in" }}>
      <span style={{ fontSize: "12.0pt", fontFamily: "Symbol" }}>
        ·
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <u>
        <span style={{ fontSize: "12.0pt" }}>Essential</span>
      </u>
      <span style={{ fontSize: "12.0pt" }}>
        : Essential Cookies are necessary for site security and to enable you to
        move around our site and use its features.
      </span>
    </p>
    <p className="MsoListParagraphCxSpMiddle">
      <span style={{ fontSize: "12.0pt" }}>&nbsp;</span>
    </p>
    <p className="MsoListParagraphCxSpMiddle" style={{ textIndent: "-.25in" }}>
      <span style={{ fontSize: "12.0pt", fontFamily: "Symbol" }}>
        ·
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <u>
        <span style={{ fontSize: "12.0pt" }}>Preferences</span>
      </u>
      <span style={{ fontSize: "12.0pt" }}>
        .&nbsp; Preference Cookies allow us to remember your preferences on the
        Site during a browsing session or across browsing session.
      </span>
    </p>
    <p className="MsoListParagraphCxSpMiddle" style={{ textIndent: "-.25in" }}>
      <span style={{ fontSize: "12.0pt", fontFamily: "Symbol" }}>
        ·
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <u>
        <span style={{ fontSize: "12.0pt" }}>Analytics</span>
      </u>
      <span style={{ fontSize: "12.0pt" }}>
        : Analytics Cookies allow us to analyze use of our Site to evaluate and
        improve performance.&nbsp;{" "}
      </span>
    </p>
    <p className="MsoListParagraphCxSpLast">
      <span style={{ fontSize: "12.0pt" }}>&nbsp;</span>
    </p>
    <p className="MsoNormal">
      <span style={{ fontSize: "12.0pt" }}>
        We don’t use any Cookies that would allow third parties to monitor your
        activity across other sites.
      </span>
    </p>
    <p className="MsoNormal">
      <span style={{ fontSize: "12.0pt" }}>&nbsp;</span>
    </p>
    <h2>
      <a id="_How_You_Can" />
      How You Can Block Cookies
    </h2>
    <p className="MsoNormal">
      <span style={{ fontSize: "12.0pt" }}>&nbsp;</span>
    </p>
    <p className="MsoNormal">
      <span style={{ fontSize: "12.0pt" }}>
        You can block, disable or delete cookies at any time by changing the
        settings in your browser. However, blocking, disabling, or deleting
        cookies may limit your ability to view all the pages of our Site or
        other sites.&nbsp; You can find more information about cookies and other
        options you may have for restricting them at{" "}
      </span>
      <a href="http://www.cookiesandyou.com/">www.cookiesandyou.com/</a>
      <span style={{ color: "blue" }}>, and </span>
      <a href="http://www.aboutads.info/choices">www.aboutads.info/choices</a>
      <span style={{ color: "blue" }}>.</span>
    </p>
    <p className="MsoNormal">
      <b>
        <span style={{ fontSize: "14.0pt" }}>&nbsp;</span>
      </b>
    </p>
    <h2>“Do Not Track” Signals</h2>
    <p className="MsoNormal">
      <span style={{ fontSize: "12.0pt" }}>&nbsp;</span>
    </p>
    <p className="MsoNormal">
      <span style={{ fontSize: "12.0pt" }}>
        Your browser may offer a Do Not Track (DNT) setting.&nbsp; If you turn
        that setting on, your browser sends a signal to websites indicating that
        you don’t want to be tracked over time or across third party
        sites.&nbsp; We don’t currently respond to these signals because there
        is not yet a common understanding of how to process them or a consensus
        on what “tracking” means.
      </span>
    </p>
    <p className="MsoNormal">
      <span style={{ fontSize: "12.0pt" }}>&nbsp;</span>
    </p>
    <h1 style={{ marginLeft: "22.5pt", textIndent: "-22.5pt" }}>
      <a id="_Toc30170204" />
      <a id="_Toc64623436">8. Your Rights And Choices</a>
    </h1>
    <p className="MsoBodyText">&nbsp;</p>
    <h2>Opt-Out Of Marketing Communications</h2>
    <h2>
      <span style={{ fontSize: "11.5pt", fontWeight: "normal" }}>
        You may opt-out of our marketing emails by using the unsubscribe link
        provided in the email
      </span>
      <span style={{ fontWeight: "normal" }}>.</span>
    </h2>
    <p className="MsoBodyText" style={{ textIndent: "0in" }}>
      &nbsp;
    </p>
    <h2>Your Browser And Device Settings</h2>
    <p className="MsoBodyText" style={{ textIndent: "0in" }}>
      See <a href="#_How_You_Can">Section 7</a> for options to restrict Cookies.{" "}
    </p>
    <p className="MsoNormal">
      <span style={{ fontSize: "12.0pt" }}>&nbsp;</span>
    </p>
    <h1>
      <a id="_Toc64623437">9. Links To Third Party Sites</a>
    </h1>
    <p className="MsoNormal">
      <span style={{ fontSize: "12.0pt" }}>&nbsp;</span>
    </p>
    <p className="MsoBodyText" style={{ textIndent: "0in" }}>
      <span style={{ fontSize: "12.0pt" }}>
        Our Site may contain links to third-party websites, products or
        services.&nbsp; If you use these links, you will leave our site. Such
        links do not constitute or imply an endorsement, sponsorship or
        recommendation by us of the third party, the third-party website or the
        information contained therein, and we shall not be responsible or liable
        for your use thereof. Such use shall be subject to the terms of use and
        privacy policies applicable to those sites.
      </span>
    </p>
    <p className="MsoNormal">
      <span style={{ fontSize: "12.0pt" }}>&nbsp;</span>
    </p>
    <h1>
      <a id="_Toc64623438">10. Third Party Direct Marketing</a>
    </h1>
    <p className="MsoNormal">
      <span style={{ fontSize: "12.0pt" }}>&nbsp;</span>
    </p>
    <p className="MsoNormal">
      <span style={{ fontSize: "12.0pt" }}>
        We don’t share Personal Information with third parties for their direct
        marketing purposes.
      </span>
    </p>
    <p className="MsoNormal">
      <span style={{ fontSize: "12.0pt" }}>&nbsp;</span>
    </p>
    <h1>
      <a id="_Toc64623439">11. Security</a>
    </h1>
    <p className="MsoNormal">
      <span style={{ fontSize: "12.0pt" }}>&nbsp;</span>
    </p>
    <p className="MsoBodyText" style={{ textIndent: "0in" }}>
      The security of your Personal Information is important to us, but remember
      that no method of transmission over the Internet, or method of electronic
      storage, is 100% secure. While we strive to use commercially reasonable
      means to protect your Personal Information, we cannot guarantee its
      absolute security.
    </p>
    <p className="MsoNormal">
      <span style={{ fontSize: "12.0pt" }}>&nbsp;</span>
    </p>
    <h1 style={{ marginLeft: "22.5pt", textIndent: "-22.5pt" }}>
      <a id="_Ref46925834" />
      <a id="_Ref46925962" />
      <a id="_Toc64623440" />
      <a id="_Data_Subjects_In" />
      12. California Residents
    </h1>
    <p className="MsoBodyText" style={{ textIndent: "0in" }}>
      &nbsp;
    </p>
    <p className="MsoBodyText" style={{ textIndent: "0in" }}>
      <span style={{ fontSize: "12.0pt" }}>
        As of January 1, 2021, Survey Miners is not a “business” as defined in
        the California Consumer Privacy Act of 2018, as amended (CCPA).&nbsp; As
        our business and operations expand, we are committed to reviewing
        applicability of the CCPA and other laws regarding privacy or data
        protection on at least an annual basis, and will update this Policy
        accordingly if necessary.
      </span>
    </p>
    <p className="MsoNormal">
      <span style={{ fontSize: "12.0pt" }}>&nbsp;</span>
    </p>
    <h1 style={{ marginLeft: "22.5pt", textIndent: "-22.5pt" }}>
      <a id="_Toc64623441">
        13. Data Subjects In The EEA, United Kingdom and Switzerland
      </a>
    </h1>
    <p className="MsoNormal">
      <span style={{ fontSize: "12.0pt" }}>&nbsp;</span>
    </p>
    <p className="MsoBodyText" style={{ textIndent: "0in" }}>
      Survey Miners is a Delaware corporation headquartered in Murrieta,
      California that does not have an office, employees or other establishment
      in the European Economic Area (EEA) or any other country outside the
      United States (collectively, with EEA countries, the “Other Countries”).
      Our services are not offered to natural persons in Other Countries and we
      do not use the Site to monitor the behavior of natural persons in Other
      Countries. If you visit our Site or contact us, your Personal Information
      will be processed in the United States—which has neither sought nor
      received a finding of “adequacy” from the European Commission as
      referenced in Article 25 of the European Union’s General Data Protection
      Regulation (GDPR)—and will be handled in accordance with this Policy and
      applicable U.S. law, unless we have agreed otherwise in writing with you
      or a third party you are affiliated with.
    </p>
    <p className="MsoNormal">
      <span style={{ fontSize: "12.0pt" }}>&nbsp;</span>
    </p>
    <h1
      style={{
        marginLeft: "22.5pt",
        textIndent: "-22.5pt",
        pageBreakAfter: "auto",
      }}
    >
      <a id="_Toc30170207" />
      <a id="_Toc64623442">14. Changes To This Policy</a>
    </h1>
    <p className="MsoNormal">
      <span style={{ fontSize: "12.0pt" }}>&nbsp;</span>
    </p>
    <p className="MsoNormal">
      This Privacy Policy is effective as of May 30, 2021and will remain in
      effect except with respect to any changes in its provisions in the future,
      which will be in effect immediately after being posted on this page. We
      reserve the right to update or change our Privacy Policy at any time and
      you should check this Privacy Policy periodically. Your continued use of
      the Site after we post any modifications to the Privacy Policy on this
      page will constitute your acknowledgment of the modifications and your
      consent to abide and be bound by the modified Privacy Policy. If we make
      any material changes to this Privacy Policy, we will notify you either
      through the email address you have provided us, or by placing a prominent
      notice on our website.
    </p>
    <p className="MsoNormal">
      <span style={{ fontSize: "12.0pt" }}>&nbsp;</span>
    </p>
    <h1
      style={{
        marginLeft: "22.5pt",
        textIndent: "-22.5pt",
        pageBreakAfter: "auto",
      }}
    >
      <a id="_Toc30170208" />
      <a id="_Toc64623443" />
      <a id="_Contact_Us" />
      15. Contact Us{" "}
    </h1>
    <p className="MsoNormal">
      <span style={{ fontSize: "12.0pt" }}>&nbsp;</span>
    </p>
    <p className="MsoNormal">
      <span style={{ fontSize: "12.0pt" }}>
        If you have any questions or concerns about this Policy, please contact
        us via email at{" "}
        <a href="mailto:info@surveyminers.com">info@surveyminers.com</a>.
      </span>
    </p>
    <p className="MsoNormal">&nbsp;</p>
  </div>
);
