import React, { createContext, useContext, useState } from "react";
import { Redirect, Route, useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

const authContext = createContext<any>({});

export function ProvideAuth({ children }: { children: JSX.Element }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export function useAuth() {
  return useContext(authContext);
}

export function useProvideAuth() {
  const [uuid, setUuid] = useState<any>(null);
  const [userName, setUserName] = useState<string>("");

  const signin = (webinarId: string, _userName: string, cb: any) => {
    const uuid = uuidv4();
    localStorage.setItem(`${webinarId}-auth`, uuid);
    setUuid(uuid);
    setUserName(_userName);
    cb();
  };

  const signout = (cb: any) => {
    setUuid(null);
    setUserName("");
  };

  return {
    uuid,
    userName,
    signin,
    signout,
  };
}

export function AuthButton() {
  let history = useHistory();
  let auth = useAuth();

  return auth.user ? (
    <p>
      Welcome!{" "}
      <button
        onClick={() => {
          auth.signout(() => history.push("/"));
        }}
      >
        Sign out
      </button>
    </p>
  ) : (
    <p>You are not logged in.</p>
  );
}

export function PrivateRoute({ children, ...rest }: any) {
  let auth = useAuth();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
