import React, {FormEvent, useEffect, useRef, useState, useCallback} from "react";
import {CampaignAnswer, CampaignQuestionKinds} from "../../../../api";
import debounce from 'lodash.debounce'

import "./question.scss";

const QUICK_SELECTION_FOR_OPEN_QUESTION = true;

interface QuestionInterface {
  id: number;
  title: string;
  subtitle?: string | null;
  answers: CampaignAnswer[];
  onClickAnswer: (id: number, answerId: number | string, kind?: CampaignQuestionKinds | null) => void;
  kind?: CampaignQuestionKinds | null;
  quickSelection?: boolean;
  openQuestionSelectedValue?: string;
}

const useOutsideClick = (callback: any) => {
    const ref = React.useRef<any>();

    React.useEffect(() => {
        const handleClick = (event: any) => {
            if (ref.current && !ref.current?.contains(event.target)) {
                callback();
            }
        };

        document.addEventListener('click', handleClick, true);

        return () => {
            document.removeEventListener('click', handleClick, true);
        };
    }, [ref]);
    return ref;
};

const caretDownIcon = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z"/></svg>

const Question = ({
  subtitle,
  id,
  title,
  answers,
  onClickAnswer,
  kind,
  quickSelection,
  openQuestionSelectedValue,
}: QuestionInterface) => {
  const [openQuestionValue, setOpenQuestionValue] = useState(openQuestionSelectedValue || '');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [dropdownMaxHeight, setDropdownMaxHeight] = useState<any>(0);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const questionBoxRef = React.useRef<any>();

  const shouldRenderOpenQuestion = kind === CampaignQuestionKinds.INPUT;

  const ref = useOutsideClick(() => setIsDropdownOpen(false));

  useEffect(() => {
      if(inputRef?.current) {
          inputRef.current.focus();
      }

      if(questionBoxRef?.current) {
          const pollingStepBox = document.getElementsByClassName('polling__step')?.[0] as any;
          if(pollingStepBox) {
              setDropdownMaxHeight(pollingStepBox?.offsetHeight ? pollingStepBox.offsetHeight - questionBoxRef?.current?.offsetHeight - 15 : 0);
          }
      }
  }, []);

  const onChangeInputQuestion = useCallback(debounce(value => onClickAnswer(id, value, kind), 100), [])

  const onOpenQuestionSubmit = (e: FormEvent) => {
    e.preventDefault();

      onClickAnswer(id, openQuestionValue, kind);
  }

  const onClickClosedAnswer = (id: number, answerId: number) => {
      setIsDropdownOpen(false)
      onClickAnswer(id, answerId);
  }

  const renderClosedQuestion = () => {
      const questions = answers.map(({ content, id: answerId, selected }) => (
          <div
              className={`question__answer ${
                  selected ? "question__answer--selected" : ""
              }`}
              key={answerId}
              onClick={() => onClickClosedAnswer(id, answerId)}
          >
              {content}
          </div>
      ));

      if(answers.length < 6) {
          return questions;
      }

      const selectedValue = answers?.find(({ selected }) => selected);

      return (
          <div ref={ref} className="question__dropdown">
              <div className={`question__dropdown-button ${isDropdownOpen ? 'question__dropdown-button--open' : ''}`} onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                  <span>{selectedValue?.content || ''}</span>
                  {caretDownIcon}
              </div>
              <div
                  className={`question__dropdown-list ${isDropdownOpen ? 'question__dropdown-list--open' : ''}`}
                  style={{
                      maxHeight: dropdownMaxHeight > 1 ? dropdownMaxHeight : 'auto'
                  }}
              >
                  {questions}
              </div>
          </div>
      );
  }

  const renderOpenQuestion = () => (
      <form onSubmit={onOpenQuestionSubmit}>
          <div className="question__open-answer">
              <input
                  ref={inputRef}
                  value={openQuestionValue}
                  onChange={e => {
                      setOpenQuestionValue(e.target.value);
                      onChangeInputQuestion(e.target.value);
                  }}
                  type="text"/>
          </div>
      </form>
  )

  return (
    <div ref={questionBoxRef} className="question">
      {subtitle && <h3>{subtitle}</h3>}
      <p>{title}</p>
      {shouldRenderOpenQuestion ? renderOpenQuestion() : renderClosedQuestion()}
    </div>
  );
};

export default Question;
