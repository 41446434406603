import React from "react";
import ReactDOM from "react-dom";
import App from "./components/app";
import reportWebVitals from "./reportWebVitals";
import "normalize.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

//bugsnag
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact, {BugsnagPluginReactResult} from '@bugsnag/plugin-react'

import "./index.scss";

Bugsnag.start({
    apiKey: '3352fde12d94f4c89ba7b52f007287df',
    plugins: [new BugsnagPluginReact()]
});

const plugin = Bugsnag.getPlugin("react") as BugsnagPluginReactResult
const ErrorBoundary = plugin.createErrorBoundary(React);

ReactDOM.render(
  <React.StrictMode>
      <ErrorBoundary>
          <App />
      </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
