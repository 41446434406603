import React from "react";
import { ReactComponent as ArrowIcon } from "../../../../images/steps_arrow.svg";

import "./steps.scss";

interface StepsInterface {
  active: number;
  size: number;
}

const Steps = ({ active, size }: StepsInterface) => {
  const percentProgress = active / size * 100;

  return (
    <div className="steps">
      {[...Array(5)].map((_, index) => {
        const isStepActive = size === 0 || index * 20 < percentProgress;
        return (
            <div
                className={`steps__item ${
                    isStepActive ? "steps__item--active" : ""
                }`}
                key={index}
            >
              <ArrowIcon className="steps__item-arrow" />
            </div>
        )
      })}
    </div>
  );
};

export default Steps;
