const translations = {
    en: {
        "polling.finish": "Finish",
        "polling.next": "Next",
        "polling.prev": "Previous",
        "polling.completed": "Survey complete!",
        "polling.thankYouCaption": "Thank you for your feedback."
    },
    ru: {
        "polling.finish": "Заканчивать",
        "polling.next": "Следующий",
        "polling.completed": "Опрос завершен!",
        "polling.thankYouCaption": "Спасибо за ваш отзыв."
    }
} as any;



export const translate = (key: string): string => {
    const LANGUAGE = process.env.REACT_APP_LANGUAGE || 'en';
    
    const translationObject = translations[LANGUAGE] || translations.en;

    return translationObject[key] || '';
}