import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router-dom";

import Navbar from "../common/navbar";
import { CornerButton } from "../common/cornerButton";
import landingImage from "../../images/landing_image.svg";
import { MODAL_TYPE, useWebinar } from "../../helpers/webinarContext";

import "./landing.scss";

const Landing = () => {
  const [webinarCode, setWebinarCode] = useState("");
  const history = useHistory();
  const { setActiveModal } = useWebinar();

  const onJoinWebinar = (e: React.FormEvent) => {
    e.preventDefault();
    window.location.href = `/${webinarCode}`;
  };

  return (
    <section className="landing-page">
      <Navbar hideItems />
      <div className="landing-page__wrapper container-fluid">
        <div className="landing-page__form">
          <h1>Watch webinars, Share feedback.</h1>
          <p>
            Your opinion counts! Enter a webinar invite code below to join
            thousands of SurveyMiners users watching webinars and sharing their
            thoughts.
          </p>
          <form
            className="landing-page__input-wrapper"
            onSubmit={onJoinWebinar}
          >
            <input
              onChange={(e) => setWebinarCode(e.target.value)}
              type="text"
              placeholder="Enter webinar invite code"
            />
            <button type="submit">Join Webinar</button>
          </form>
        </div>
        <div className="landing-page__photo">
          <img src={landingImage} alt="Webinars" />
        </div>
      </div>
      <CornerButton
        text="Privacy Policy"
        onClick={() => setActiveModal(MODAL_TYPE.PRIVACY)}
      />
    </section>
  );
};

export default Landing;
