import React from "react";
import { ReactComponent as CheckIcon } from "../../../../images/circle_check_icon.svg";
import { translate } from "../../../../translations";

import "./success.scss";

const Success = ({callToActionText, callToActionLink, caption, scoreMessage, thankYouMsg}: {
    callToActionText?: string | null;
    callToActionLink?: string | null;
    caption?: string | null;
    thankYouMsg?: string | null;
    scoreMessage?: string | null;
}) => {
  return (
    <div className="success">
        <CheckIcon />
        <h1>
            {
                thankYouMsg
                ?
                <div dangerouslySetInnerHTML={{__html: thankYouMsg}}/>
                : 
                <>
                    {translate('polling.completed')}
                    <br /><br />
                </>
            }
            <br /><br />
            
            {!thankYouMsg && (!!caption ? caption : translate('polling.thankYouCaption'))}
            {scoreMessage && (
                <>
                    <br />
                    <h1>{scoreMessage}</h1>
                </>
            )}
        </h1>
        {callToActionLink && callToActionText ?
            (
                <a className="button" target="_blank" href={callToActionLink}>
                    {callToActionText}
                </a>
            ) : null
        }
    </div>
  );
};

export default Success;
