import React, { useState } from "react";
import { Link } from "react-router-dom";

import { ReactComponent as BurgerIcon } from "../../../images/burger.svg";
import { ReactComponent as Logo } from "../../../images/logo.svg";

import "./navbar.scss";

const Navbar = ({ hideItems }: { hideItems?: boolean }) => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  return (
    <nav className="navbar">
      <div className="navbar__container container-fluid">
        <Link className="navbar__logo-link" to="/">
          <Logo className="navbar__logo" />
        </Link>

        {!hideItems && (
          <>
            {/*mobile*/}
            <button
              className="navbar__toggler"
              onClick={() => setIsNavOpen(!isNavOpen)}
              type="button"
            >
              <BurgerIcon />
            </button>

            <div
              className={`navbar__collapse ${
                isNavOpen ? "navbar__collapse--open" : ""
              }`}
            >
              <ul className="navbar__nav">
                <li className="navbar__item navbar__item--active">
                  <a href="#">Home</a>
                </li>
                <li className="navbar__item">
                  <a href="#">About</a>
                </li>
                <li className="navbar__item">
                  <a href="#">Help</a>
                </li>
              </ul>
            </div>
          </>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
