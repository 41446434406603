import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ReactPlayer from "react-player";
import Loader from "react-loader-spinner";
// import Vimeo from "@u-wave/react-vimeo";
import {Redirect, RouteComponentProps, useLocation} from "react-router-dom";
import { ReactComponent as TimerIcon } from "../../images/timer.svg";
import { ReactComponent as Tick } from "../../images/tick.svg";
import ParticipantsIcon from "../../images/participants.png";
import SendIcon from "../../images/send.png";
import { useWebinar } from "../../helpers/webinarContext";
import { useAuth } from "../../helpers/auth";
import { isWindows } from "react-device-detect";
import ReactPixel from "react-facebook-pixel";

import "./webinar.scss";

const flagemojiToPNG = (flag: any) => {
  const countryCode = Array.from(flag, (codeUnit: any) =>
    codeUnit.codePointAt()
  )
    .map((char) => String.fromCharCode(char - 127397).toLowerCase())
    .join("");
  return `<img width="14" src="https://flagcdn.com/16x12/${countryCode}.png" alt="flag" />`;
};
const emojiReg = new RegExp("(?:\ud83c[\udde6-\uddff]){2}", "g");

const formatTimerNumber = (number: number) => {
  if (number < 10) {
    return `0${Math.floor(number)}`;
  }

  return `${Math.floor(number)}`;
};

const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const parseTimeCodeQueryToSeconds = (timeCodeQuery?: string) => {
  if(!timeCodeQuery?.length) {
    return 0;
  }
  const time = timeCodeQuery.split("");
  let times = {} as any;
  let code = '';
  time.forEach((i) => {
    if(Number(i) || i == '0') {
      code += i;
    }
    if(['s', 'm', 'h'].includes(i)) {
      times[i] = code;
      code = '';
    }
    return i;
  })

  const seconds = Number(times['s']) || 0;
  const minutes = Number(times['m']) || 0;
  const hours = Number(times['h']) || 0;

  return seconds + (minutes * 60) + (hours * 3600);
}

interface RouteParams {
  webinarId: string;
}

const WebinarPage = (props: RouteComponentProps<RouteParams>) => {
  const webinar = useWebinar();
  const auth = useAuth();
  const queryParams = useQuery();
  const videoRef = useRef<any>(undefined);
  const chatRef = useRef<any>(undefined);
  const [isVideoPlaying, setIsVideoPlaying] = useState<Boolean | undefined>(
    undefined
  );
  const initialTimeCode = queryParams?.get("t");
  const [videoTimeCode, setVideoTimeCode] = useState(0);
  const [activeQuestionId, setActiveQuestionId] = useState<number | undefined>(
    undefined
  );
  const [selectedAnswer, setSelectedAnswer] = useState<number | undefined>(
    undefined
  );
  const [shouldShowSuccessMessage, setShouldShowSuccessMessage] =
    useState(false);
  const [message, setMessage] = useState("");
  const participantsCounter =
    webinar.data?.attributes.number_of_participants || 27;
  const [messages, setMessages] = useState(
    webinar.video?.attributes?.chat_messages || []
  );

  const [videoUrl, setVideoUrl] = useState(
    webinar.video?.attributes?.video_url || ""
  );
  // @ts-ignore
  const isFbPixelConnected = !!window?.fbq?.instance;

  useEffect(() => {
    const PIXEL_ID = process.env.REACT_APP_PIXEL_ID; // get from API in the future
    if (PIXEL_ID) {
      ReactPixel.init(PIXEL_ID, undefined, {
        autoConfig: true,
        debug: false,
      });
    }
  }, []);

  const activeQuestion = useMemo(() => {
    return (webinar?.surveys || []).find(
      ({ id }) => activeQuestionId == Number(id)
    );
  }, [activeQuestionId]);

  const onProgressVideo = useCallback(({ playedSeconds }) => {
    if(playedSeconds == 0 && !!initialTimeCode) {
      return
    }
    setVideoTimeCode(Math.floor(playedSeconds));

    if (!activeQuestionId) {
      const surveysFromVideo = webinar.video?.attributes?.survey_forms || [];

      const surveyToShow = surveysFromVideo.find(
        ({ video_stop_time }) =>
          Number(video_stop_time) == Math.floor(Number(playedSeconds))
      );
      if (surveyToShow) {
        setActiveQuestionId(surveyToShow.survey_form_id);
      }
    }
  }, []);

  useEffect(() => {
    if (chatRef.current && videoTimeCode) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  }, [videoTimeCode]);

  const timer = useMemo(() => {
    const seconds = formatTimerNumber(videoTimeCode % 60);
    const minutes = formatTimerNumber((videoTimeCode / 60) % 60);
    const hours = formatTimerNumber((videoTimeCode / 3600) % 60);
    return `${hours}:${minutes}:${seconds}`;
  }, [videoTimeCode]);

  if (!webinar.data) {
    const webinarHash = props.match?.params?.webinarId;
    return <Redirect to={webinarHash ? `/${webinarHash}${initialTimeCode ? '?t=' + initialTimeCode : ''}` : "/"} />;
  }

  if (webinar.data && webinar.isLoading) {
    return (
      <div className="loader-wrapper">
        <Loader type="TailSpin" color="#000" height={50} width={50} />
      </div>
    );
  }

  const onClickVideo = () => {
    setIsVideoPlaying(!isVideoPlaying);
  };

  const chatMessages = messages.map(({ content, name, show_at }, index) => {
    if (Number(show_at) < Number(videoTimeCode)) {
      return (
        <div key={`${name}-${index}`} className="webinar__chat-item">
          <div className="webinar__chat-name">{name}</div>
          {isWindows ? (
            <div
              className="webinar__chat-message"
              dangerouslySetInnerHTML={{
                /*@ts-ignore*/
                __html: content.replaceAll(emojiReg, flagemojiToPNG),
              }}
            />
          ) : (
            <div className="webinar__chat-message">{content}</div>
          )}
        </div>
      );
    }
    return null;
  });

  const shouldShowEmptyState =
    !activeQuestionId &&
    (messages.length < 1 ||
      Number(messages[0]?.show_at) > Number(videoTimeCode));

  const onVote = (
    formId: number | undefined,
    questionId: number,
    answerId: number | undefined
  ) => {
    console.log(formId);
    console.log(questionId);
    console.log(answerId);
    if (answerId && webinar?.video?.id && formId) {
      setShouldShowSuccessMessage(true);
      webinar.addVote(webinar?.video?.id, formId, questionId, answerId);

      if (webinar?.data?.id) {
        ReactPixel.trackCustom("FilledForm", {
          type: "webinar",
          id: webinar.data.id,
          answer_ids: [answerId],
        });
      }

      setTimeout(() => {
        setActiveQuestionId(undefined);
        setSelectedAnswer(undefined);
        setShouldShowSuccessMessage(false);
      }, 3000);
    }

    return null;
  };

  const onSendMessage = (e: React.FormEvent) => {
    e.preventDefault();

    if (message.length > 0 && message.replace(/\s/g, "").length > 0) {
      const messageToAdd = {
        name: auth.userName || "Anonymous",
        content: message,
        show_at: `${videoTimeCode - 1}`,
      };
      const newMessages = [...messages, messageToAdd].sort(
        (a, b) => Number(a.show_at) - Number(b.show_at)
      );
      webinar.addMessage(message, auth.userName);
      setMessages(newMessages);
      setMessage("");
    }

    return null;
  };

  const getWebinarPool = () => {
    if (!activeQuestion) {
      return null;
    }
    const question = activeQuestion?.attributes?.questions?.[0];
    return (
      question && (
        <div className="webinar__poll">
          <div className="webinar__poll-title">Poll</div>
          <div className="webinar__poll-question">{question.question}</div>
          <div className="webinar__poll-hr" />
          {!shouldShowSuccessMessage ? (
            <>
              <div className="webinar__poll-options">
                {(question.answers || []).map(({ answer, id }) => (
                  <div
                    key={id}
                    className={`webinar__poll-option ${
                      selectedAnswer == id
                        ? "webinar__poll-option--selected"
                        : ""
                    }`}
                    onClick={() => setSelectedAnswer(id)}
                  >
                    {answer}
                  </div>
                ))}
              </div>

              <div
                className={`webinar__poll-button ${
                  !selectedAnswer ? "webinar__poll-button--disabled" : ""
                }`}
                onClick={() =>
                  onVote(activeQuestionId, question.id, selectedAnswer)
                }
              >
                Vote
              </div>
            </>
          ) : (
            <div className="webinar__poll-success-message">
              <Tick /> Thanks for your vote!
            </div>
          )}
        </div>
      )
    );
  };

  return (
    <section className="webinar">
      <div className="webinar__container container">
        <div className="webinar__mobile-bar">
          <div className="webinar__mobile-bar-item">
            <img src={ParticipantsIcon} />
            <span>{participantsCounter}</span>
          </div>
          <div className="webinar__mobile-bar-item">
            <TimerIcon />
            <span>{timer}</span>
          </div>
        </div>
        <div className="webinar__video">
          <div className="webinar__video-header">
            <h2>{webinar?.data?.attributes?.title}</h2>
            <span>
              <img src={ParticipantsIcon} /> {participantsCounter} Active
              Participants
            </span>
          </div>
          <div className="webinar__video-wrapper" onClick={onClickVideo}>
            <div
              className={`webinar__video-start ${
                isVideoPlaying ? "webinar__video-start--hide" : ""
              }`}
            >
              <span>
                {Number(videoTimeCode) > 0 ? "Resume" : "Start"} Webinar
              </span>
            </div>
            {/*<Vimeo*/}
            {/*    ref={videoRef}*/}
            {/*    video={videoUrl}*/}
            {/*    // autoplay*/}
            {/*    controls={false}*/}
            {/*    responsive*/}
            {/*    onTimeUpdate={onProgressVideo}*/}
            {/*    paused={!isVideoPlaying}*/}
            {/*/>*/}
            <ReactPlayer
              id="video-player"
              ref={videoRef}
              className="webinar__video-player"
              playing={!!isVideoPlaying}
              onProgress={onProgressVideo}
              onReady={() => {
                const initialTime = parseTimeCodeQueryToSeconds(initialTimeCode || '');
                if(initialTime) {
                  videoRef?.current?.seekTo(initialTime);
                  onProgressVideo({playedSeconds: initialTime});
                }
              }}
              controls={false}
              playsinline
              height="100%"
              width="100%"
              url={videoUrl}
              config={{
                vimeo: {
                  // @ts-ignore
                  byline: true,
                  responsive: true,
                  playsinline: true,
                },
              }}
            />
          </div>
        </div>
        <aside className="webinar__sidebar">
          <div className="webinar__timecode">
            <TimerIcon />
            <span>{timer}</span>
          </div>
          <div
            className={`webinar__chat ${
              activeQuestionId ? "webinar__chat--mobile-hide" : ""
            }`}
          >
            {shouldShowEmptyState && (
              <div
                className="webinar__sidebar-empty-state"
                dangerouslySetInnerHTML={{
                  __html: webinar.data.attributes.description,
                }}
              />
            )}
            <div ref={chatRef} className="webinar__chat-list">
              {chatMessages}
            </div>

            <form
              className="webinar__chat-input-wrapper"
              onSubmit={onSendMessage}
            >
              <input
                className="webinar__chat-input"
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Type your message here"
                type="text"
                value={message}
                autoComplete="off"
              />
              <button type="submit">
                <img src={SendIcon} />
              </button>
            </form>
          </div>
          {getWebinarPool()}
        </aside>
      </div>
    </section>
  );
};

export default WebinarPage;
