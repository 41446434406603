import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Modal from "react-modal";
import { ProvideAuth } from "../../helpers/auth";
import {
  MODAL_TYPE,
  ProvideWebinar,
  useWebinar,
} from "../../helpers/webinarContext";
import { ReactComponent as CloseIcon } from "../../images/close.svg";

import HomePage from "../home";
import WebinarPage from "../webinar";
import Landing from "../landing";
import { PollingPage } from "../polling";

import "./app.scss";
import { PrivacyPolicy } from "./PrivacyPolicy";

const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "600px",
    maxHeight: "90%",
    maxWidth: "90%",
  },
};

const Content = () => {
  const { activeModal, setActiveModal } = useWebinar();

  return (
    <>
      <Router>
        <div className="app">
          <Switch>
            <Route path="/polling/:pollingId" component={PollingPage} />
            <Route exact path="/:webinarId/webinar" component={WebinarPage} />
            <Route exact path="/:webinarId" component={HomePage} />
            <Route component={Landing} />
          </Switch>
        </div>
      </Router>
      <Modal
        isOpen={activeModal === MODAL_TYPE.PRIVACY}
        onRequestClose={() => setActiveModal(undefined)}
        style={modalStyles}
        contentLabel="Privacy Policy"
      >
        <span
          className="modal__close-button"
          onClick={() => setActiveModal(undefined)}
        >
          <CloseIcon />
        </span>
        <div className="modal__content">
          <PrivacyPolicy />
        </div>
      </Modal>
    </>
  );
};

const App = () => {
  useEffect(() => {
    const title = process.env.REACT_APP_SITE_TITLE || 'Survey Miners';
    const metaDescription = process.env.REACT_APP_SITE_DESCRIPTION || 'Provide important feedback in webinars';
    document.title = title;
    document?.querySelector('meta[name="description"]')?.setAttribute('content', metaDescription);
  }, []);

  return (
    <ProvideAuth>
      <ProvideWebinar>
        <Content />
      </ProvideWebinar>
    </ProvideAuth>
  );
};
export default App;
